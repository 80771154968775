import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import tasksSlice from './slices/tasksSlice';
import filterSlice from './slices/filterSlices';
import notificationSlice from './slices/notificationSlice';
import { projectsReducer } from './slices/projects/projects';
import { tasksListReducer } from './slices/tasksList/tasksList';

export const store = configureStore({
  reducer: {
    tasks: tasksSlice,
    filter: filterSlice,
    notifications: notificationSlice,
    projects: projectsReducer,
    tasksList: tasksListReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
