import { TextField, TextFieldProps } from '@mui/material';
import React from 'react';

type IFormInputProps = {
  colors?: { headColor?: string; textColor?: string };
} & TextFieldProps;

const TextMultiline: React.FC<IFormInputProps> = ({ colors = {}, ...otherProps }) => {
  const { headColor = '#FFF', textColor = '#FFF' } = colors;
  return (
    <TextField
      id="outlined-textarea"
      variant="standard"
      multiline
      {...otherProps}
      sx={{
        '&:hover div:before': {
          borderBottom: '1px solid #bfc8e8!important',
        },
      }}
      InputLabelProps={{
        sx: {
          color: `${headColor}!important`,
          fontSize: '18px',
        },
      }}
      inputProps={{
        sx: {
          color: textColor,
          fontSize: '23px',
          fontWeight: 600,
        },
      }}
      InputProps={{
        sx: {
          '&:before': {
            borderBottom: '1px solid #bfc8e8!important',
          },
        },
      }}
    />
  );
};

export default TextMultiline;
