import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchTasksList, fetchTaskEdit, fetchСreateTask, fetchDeleteTask } from './tasksListApi';
import { TaskListReqType, TaskListResType, IPatchTaskReq } from '@shared/types';

interface IUserState {
  status: 'idle' | 'success' | 'loading' | 'failed';
  error: unknown;
  tasks: TaskListResType[];
}

export const getTasksList = createAsyncThunk(
  '@@tasksList/getTasksList',
  async (arg: { token: string; data: TaskListReqType }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { token, data } = arg;
      const response = await fetchTasksList(token, data);
      const json = await response.json();
      return fulfillWithValue(json.Tasks);
    } catch (error: unknown) {
      return rejectWithValue(error);
    }
  }
);

export const patchTaskEdit = createAsyncThunk(
  '@@taskEdit/patchTaskAdd',
  async (
    arg: { token: string; data: IPatchTaskReq },
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const { token, data } = arg;
      const response = await fetchTaskEdit(token, data);
      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`);
      }
      return response.status;
    } catch (error: unknown) {
      return rejectWithValue(error);
    }
  }
);

export const createTask = createAsyncThunk(
  '@@taskEdit/addTask',
  async (
    arg: { token: string; data: {} },
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const { token, data } = arg;
      const response = await fetchСreateTask(token, data);
      const json = await response.json();
      return fulfillWithValue(json);
    } catch (error: unknown) {
      return rejectWithValue(error);
    }
  }
);

export const deleteTask = createAsyncThunk(
  '@@taskEdit/deleteTask',
  async (
    arg: { token: string; data: { UID: string } },
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const { token, data } = arg;
      const UID = data.UID
      const response = await fetchDeleteTask(token, data);
      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`);
      }
      return fulfillWithValue(UID);
    } catch (error: unknown) {
      return rejectWithValue(error);
    }
  }
);

const initialState: IUserState = {
  status: 'idle',
  error: null,
  tasks: [
    {
      UID: '',
      Deleted: '',
      Number: '',
      Date: '',
      Initiator: {UID: '', UID2: '', Name: ''},
      Excutor: {UID: '', UID2: '', Name: ''},
      Subject: '',
      Status: '',
      Project: {UID: '', Name: ''},
      SubTasksTotal: 0,
      Files: 0,
      Comments: 0,
      Organization: {UID: '', Name: ''},
    },
  ],
};

const tasksListSlice = createSlice({
  name: '@@tasksList',
  initialState,
  reducers: {
    updateTaskStatus: (state, action: PayloadAction<TaskListResType>) => {
      const { UID, Status } = action.payload;
      const index = state.tasks.findIndex((task) => task.UID === UID);

      if (index !== -1) {
        state.tasks[index].Status = Status;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTasksList.fulfilled, (state, action) => {
        state.status = 'success';
        state.tasks = action.payload;
      })
      .addCase(patchTaskEdit.fulfilled, (state, action) => {
        state.status = 'success';
      })
      .addCase(createTask.fulfilled, (state, action) => {
        state.status = 'success';
        state.tasks.push(action.payload);
      })
      .addCase(deleteTask.fulfilled, (state, action) => {
        state.status = 'success';
        state.tasks = state.tasks.filter(task => task.UID !== action.payload);
      })
      .addMatcher(
        (action) => action.type.endsWith('/pending'),
        (state) => {
          state.status = 'loading';
          state.error = '';
        }
      )
      .addMatcher(
        (action) => action.type.endsWith('/rejected'),
        (state, action) => {
          state.status = 'failed';
          state.error = action.payload.statusText;
        }
      );
  },
});

export const { updateTaskStatus } = tasksListSlice.actions;

export const tasksListReducer = tasksListSlice.reducer;

export const selectTasksList = (state: { tasksList: IUserState }) => state.tasksList.tasks;
export const selectTasksListStatus = (state: { tasksList: IUserState }) => state.tasksList.status;
