import { useState } from 'react';
import {
  Typography,
  Button,
  Modal,
  TextField,
  Box,
} from '@mui/material';
import { addProjects } from '@redux/slices/projects/projects';
import { useAppDispatch } from '@redux/store';

const NewProjectModal = ({ open, handleClose, handleCreate }) => {
  const dispatch = useAppDispatch();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const pass = localStorage.getItem('userInfo') || '';

  const handleSubmit = () => {
    const newProject = {
      name: title,
      description: description,
    };
    dispatch(addProjects({token: pass, body: newProject}))
    handleClose();
    setTitle('');
    setDescription('');
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          p: 4,
          boxShadow: 24,
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" gutterBottom>
          Создать новый проект
        </Typography>
        <TextField
          label="Название"
          fullWidth
          margin="normal"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <TextField
          label="Описание"
          fullWidth
          margin="normal"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <Button variant="contained" onClick={handleSubmit}>
          Создать
        </Button>
      </Box>
    </Modal>
  );
};

export default NewProjectModal;