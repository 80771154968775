import { createBrowserRouter, Navigate } from 'react-router-dom';
import {
  TaskPage,
  TasksPage,
  NewTaskPage,
  StartPage,
  AuthPage,
  NoDeskPage,
  ProjectsPage
} from 'pages';
import { LayoutSubHeader, PageLayout, } from '@entities/Layouts';
import ProtectedRoutes from '../provider/index';
import TasksBoard from '@components/TasksBoard/TasksBoard';
import ModalTask from '@components/Modal/ModalTask/ModalTask';

export const routes = createBrowserRouter([
  {
    path: '/auth',
    element: <AuthPage />,
  },
  {
    element: <ProtectedRoutes />,
    children: [
      {
        element: <PageLayout />,
        children: [
          {
            path: '/',
            element: <Navigate to="/projects" replace />, // Перенаправление с корневого пути на /projects
          },
          {
            path: '/projects',
            // element: <StartPage />,
            element: <ProjectsPage />
          },
          {
            element: <LayoutSubHeader />,
            children: [
              {
                path: '/projects/:UID',
                element: <TasksBoard />,
                children: [
                  // {
                  //   path: 'new',
                  //   element: <NewTaskPage />,
                  // },
                  // {
                  //   path: ':id',
                  //   element: <ModalTask />,
                  // },
                ],
              },
            ]
          },
          {
            path: '*',
            element: <NoDeskPage />,
          },
        ],
      },
    ],
  },
]);

// {
//   path: '/auth',
//   element: <AuthPage />,
// },
// {
//   element: <ProtectedRoutes />,
//   children: [
//     {
//       element: <PageLayout />,
//       children: [
//         {
//           path: '/',
//           element: <StartPage />,
//         },
//         {
// element: <SubHeader />,
// children=[

// ]
//         },
//         {
//           path: '/tasks',
//           element: <TasksBoard />,
//           children: [
//             // {
//             //   path: 'new',
//             //   element: <NewTaskPage />,
//             // },
//             // {
//             //   path: ':id',
//             //   element: <ModalTask />,
//             // },
//           ],
//         },
//         {
//           path: '*',
//           element: <NoDeskPage />,
//         },
//       ],
//     },
//   ],
// },