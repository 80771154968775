// import company from './assets/company_logo.svg';
import home from './assets/home.svg';
import more from './assets/more.svg';
// import profile from './assets/profile.svg';
// import projects from './assets/projects.svg';
// import reports from './assets/reports.svg';
import tasks from './assets/tasks.svg';
import users from './assets/users.svg';

export interface SideBarInterface {
  text: string;
  path: string;
  icon?: string;
}

const menuList = (text: string, path: string, icon?: string): SideBarInterface => {
  return {
    text,
    path,
    icon: icon || '',
  };
};

export const footerConfig: SideBarInterface[] = [
  menuList('Главная', '/', home),
  menuList('Задачи', '/tasks', tasks),
  menuList('Пользователи', '/users', users),
  menuList('Ещё', '/more', more),
];
