import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import cn from 'classnames';
import styles from './styles.module.scss';
import { footerConfig } from './config';

export const Footer = () => {
  const { pathname } = useLocation();

  return (
    <footer
      className={cn(
        styles.footer,
        navigator.userAgent.includes('Mac') &&
          window.matchMedia('(display-mode: standalone)').matches &&
          styles.footer_IPhone
      )}
    >
      {footerConfig.map((el) => (
        <Link
          to={el.path}
          className={cn(styles.link, pathname === el.path && styles.link_active)}
          key={el.text}
        >
          <img src={el.icon} className={(styles.logo)}  alt={el.text} />
          <p className={styles.text}>{el.text}</p>
        </Link>
      ))}

      {/* <Link to={'/'} className={cn(styles.link, router.pathname === '/' && styles.link_active)}>
        <span className={cn(styles.span, styles.main)} />
        <p className={styles.text}>Главная</p>
      </Link>
      <Link
        to={'/tasks'}
        className={cn(styles.link, router.pathname === '/tasks' && styles.link_active)}
      >
        <span className={cn(styles.span, styles.tasks)} />
        <p className={styles.text}>Задачи</p>
      </Link>
      <Link
        to={'/users'}
        className={cn(styles.link, router.pathname === '/users' && styles.link_active)}
      >
        <span className={cn(styles.span, styles.users)} />
        <p className={styles.text}>Пользователи</p>
      </Link> */}
      {/* <Link
        to={'/reports'}
        className={cn(styles.link, router.pathname === '/reports' && styles.link_active)}
      >
        <span className={styles.reports} />
        <p className={styles.text}>Мониторинг</p>
      </Link> */}
      {/* <Link
        to={'/more'}
        className={cn(styles.link, router.pathname === '/more' && styles.link_active)}
      >
        <span className={cn(styles.span, styles.more)} />
        <p className={styles.text}>Ещё</p>
      </Link> */}
    </footer>
  );
};
