import React, { useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';

function ProtectedRoute() {
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthentication = () => {
      const pass = localStorage.getItem('userInfo');
      if (!pass) {
        navigate('/auth');
        window.location.reload();
      }
    };

    checkAuthentication();
  }, [navigate]);

  return <Outlet />;
}

export default ProtectedRoute;
