import React from 'react';
import { Menu, MenuItem, IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

interface ProjectMenuProps {
  anchorEl: null | HTMLElement;
  open: boolean;
  onClose: (event: any) => void;
  onDelete: (event: any) => void;
  onMenuClick: (event: React.MouseEvent<HTMLElement>) => void;
}

const ProjectMenu: React.FC<ProjectMenuProps> = ({ anchorEl, open, onClose, onDelete, onMenuClick }) => {
  return (
    <>
      <IconButton
        aria-label="settings"
        sx={{ position: 'absolute', top: 8, right: 8 }}
        onClick={onMenuClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
      >
        <MenuItem onClick={onDelete}>Delete Project</MenuItem>
      </Menu>
    </>
  );
};

export default ProjectMenu;