import React from 'react';
import styles from './styles.module.scss';
import { NavSideBar, Header, Footer, SubHeader } from '@entities/Components';
import { useLocation } from 'react-router-dom';
import { Outlet } from 'react-router';

const PageLayout: React.FC = () => {
  const location = useLocation();
  let heading = '';

  switch (location.pathname) {
    case '/tasks':
      heading = 'Управление задачами';
      break;
    default:
      heading = '';
      break;
  }

  return (
    <div className={styles.page}>
      {/* <NavSideBar /> */}
      <div className={styles.content}>
        <Header text={heading} />
        {/* <SubHeader /> */}
        <div className={styles.children}>
          <Outlet />
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default PageLayout;
