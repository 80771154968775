import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Typography,
  Card,
  CardContent,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { deleteProjects } from '@redux/slices/projects/projects';
import { useAppDispatch } from '@redux/store';
import ProjectMenu from '@widgets/ProjectMenu';
import DeleteProjectDialog from '@widgets/DeleteProjectDialog';

const ProjectCard = ({ project }) => {
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();
  const pass = localStorage.getItem('userInfo') || '';

  const handleMenuClick = (event) => {
    event.stopPropagation(); // Останавливаем всплытие события
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (event) => {
    event.stopPropagation(); // Останавливаем всплытие события
    setAnchorEl(null);
  };

  const handleDeleteClick = (event) => {
    event.stopPropagation(); // Останавливаем всплытие события
    setOpenDialog(true);
    handleMenuClose(event);
  };

  const handleDialogClose = (event) => {
    event.stopPropagation(); // Останавливаем всплытие события
    setOpenDialog(false);
  };

  const handleDeleteConfirm = (event) => {
    event.stopPropagation(); // Останавливаем всплытие события
    dispatch(deleteProjects({token: pass, body: { UID: project.UID }}))
    handleDialogClose(event);
  };

  const handleCardClick = () => {
    navigate(`/projects/${project.UID}`);
  };

    return (
      <Card sx={{ backgroundColor: '#f6f8fa', position: 'relative', cursor:'pointer' }} onClick={handleCardClick}>
      <CardContent>
        <Typography variant="h5">{project.Name}</Typography>
        <Typography variant="body2">{project.Description}</Typography>
      </CardContent>
      <ProjectMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        onDelete={handleDeleteClick}
        onMenuClick={handleMenuClick}
      />
      <DeleteProjectDialog
        open={openDialog}
        onClose={handleDialogClose}
        onConfirm={handleDeleteConfirm}
      />
    </Card>
    );
  };

  export default ProjectCard;