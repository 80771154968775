import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AppBar, Toolbar, Button, Hidden } from '@mui/material'; // Импортируйте необходимые компоненты из Material-UI
import { useAppSelector } from '@redux/store';
import { selectProjects } from '@redux/slices/projects/projects';

const SubHeader: React.FC = () => {
  const location = useLocation();
  const projects = useAppSelector(selectProjects);

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: '#F4F4F4',
        color: 'black',
        height: 'auto',
        boxShadow: 'none',
        paddingTop: '10px',
        fontFamily: 'sans-serif Roboto',
      }}
    >
      <Toolbar sx={{ height: '36px', '@media (min-width: 600px)': { minHeight: 'unset' } }}>
      {projects.Projects.map((project) => (
          <Button
            key={project.UID}
            component={Link}
            to={`/projects/${project.UID}`}
            sx={{
              textDecoration: 'none',
              textTransform: 'none',
              color: 'inherit',
              marginRight: 2,
              borderRadius: '0px',
              ...(location.pathname === `/projects/${project.UID}` && {
                backgroundColor: 'white',
                borderTop: '1px solid #d0d7de',
                borderLeft: '1px solid #d0d7de',
                borderRight: '1px solid #d0d7de',
                borderTopRightRadius: '6px',
                borderTopLeftRadius: '6px',
              }),
            }}
          >
            {project.Name}
          </Button>
        ))}
      </Toolbar>
      <div style={{ borderTop: '1px solid #ccc' }}></div>
    </AppBar>
  );
};

export default SubHeader;
