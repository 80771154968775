import { useState } from 'react';
import { Link } from 'react-router-dom';

import styles from './styles.module.scss';
import { TasksList } from '@widgets/Lists';
import { PageLayout } from '@entities/Layouts';
import { CreateNewTaskButton } from '@shared/UI/Buttons';
import ProtectedRoute from '@shared/HOC/ProtectedRoute';

const TasksPage = () => {
  const [readOnly, setReadOnly] = useState(true);
  return (
    // <PageLayout
    //   heading={'Задачи'}
    //   canGoBack={true}
    //   headerChildren={
    //     window.innerWidth > 1000 && !readOnly ? <CreateNewTaskButton link={'/tasks/new'} /> : <></>
    //   }
    // >
    <>
      <div className={styles.column}>
        <TasksList setReadOnly={setReadOnly} />
      </div>
      <Link to={'/tasks/new'} className={styles.newTask} />

      <div className={styles.additional}></div>
    </>
    // </PageLayout>
  );
};

export default ProtectedRoute(TasksPage);
