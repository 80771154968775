import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
// import { useSelector } from 'react-redux';

import userAvatar from './assets/avatar.png';
import styles from './styles.module.scss';
import { AuthContext } from '@contexts/CurrUserContext';
import { StartPageLink } from '@entities/Components';

const UserStartPage = () => {
  const { userInfo } = useContext(AuthContext);

  return (
    <div className={styles.component}>
      <div className={styles.heading}>
        <Link to="/profile" className={styles.userInfo}>
          <img
            className={styles.userAvatar}
            src={userInfo.Avatar || userAvatar}
            alt="user avatar"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = userAvatar;
            }}
          />
          <h2 className={styles.userName}>{userInfo.Name || userInfo.FullName}</h2>
        </Link>
        <p className={styles.text}>
          Сегодня {new Date().toISOString().split('T')[0].split('-').reverse().join('.')}
        </p>
      </div>
      <div className={styles.items}>
        <StartPageLink
          text={'Часто задаваемые вопросы'}
          link={'knowledge'}
          linkText={'Изучить'}
          img={''}
        />
      </div>
    </div>
  );
};

export default UserStartPage;
