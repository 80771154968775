import { Base64 } from 'js-base64';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

const { REACT_APP_FB_BASE_URL = 'https://example.ru/' } = process.env;

// обработчик ответа
const handleReturn = (res: any) => (res.ok ? res.json() : Promise.reject(res.status));

// заголовки запроса
const handleHeaders = () => {
  const pass = localStorage.getItem('userInfo');
  // const authPass = handleDecodeFromBase64(pass)
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: pass || '',
  };
};

// авторизация
export const handleAuth = (v: string) => {
  return fetch(`${REACT_APP_FB_BASE_URL}users/auth`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: v,
    },
  }).then((res) => handleReturn(res));
};

// запросы для редакса
export const fetchPOSTData = (url: string, successAction: any): Promise<any> => {
  return fetch(`${REACT_APP_FB_BASE_URL}${url}`, {
    method: 'POST',
    headers: handleHeaders(),
    body: JSON.stringify({}),
  })
    .then((res) => handleReturn(res))
    .then((res) => successAction(res))
    .catch((err) => console.error(err.message));
};

// простой запрос
export const fetchDataPostSimple = (url_text: string, reqBody?: any) => {
  let bodyObject = {};
  Object.keys(reqBody).length !== 0 && (bodyObject = { ...bodyObject, ...reqBody });
  return fetch(`${REACT_APP_FB_BASE_URL}${url_text}`, {
    method: 'POST',
    headers: handleHeaders(),
    body: JSON.stringify(bodyObject),
  }).then((res) => handleReturn(res));
};

// отправить токен для уведомлений
export const handleSendNotificationToken = (item: string) => {
  return fetch(`${REACT_APP_FB_BASE_URL}tokens/add`, {
    method: 'POST',
    headers: handleHeaders(),
    body: JSON.stringify({
      Token: item, // токен пользователя
    }),
  }).then((res) => (res.ok ? res : Promise.reject(res.status)));
};

// простой запрос изменение
export const fetchDataPatchSimple = (url_text: string, reqBody?: any) => {
  let bodyObject = {};
  Object.keys(reqBody).length !== 0 && (bodyObject = { ...bodyObject, ...reqBody });
  return fetch(`${REACT_APP_FB_BASE_URL}${url_text}`, {
    method: 'PATCH',
    headers: handleHeaders(),
    body: JSON.stringify(bodyObject),
  }).then((res) => handleReturn(res));
};

// next is legacy

const handleEncodedSomeStringToBase64 = (v: string) => 'Basic ' + Base64.encode(v);

// отредактировать карточку с задачей
export const handleEditTask = (item: any) => {
  const { pass } = JSON.parse(localStorage.getItem('userInfo') || '');
  let result = {
    TaskSubject: item.TaskSubject, // тема задачи (обязательно)
    TaskTo: item.TaskTo, // Email исполнителя задачи
    TaskFrom: item.TaskFrom, // Email автора задачи (если не указан - текущий пользователь)
    TaskStatus: item.TaskStatus || '', // если не указано - черновик. допустимые значения черновик / назначена
    TaskProject: item.TaskProject, // UID проекта
    TaskNote: item.TaskNote || '', // описание задачи в формате HTML
  };
  item.TaskDeadLine?.length && Object.assign(result, { TaskDeadLine: item.TaskDeadLine });

  return fetch(`${REACT_APP_FB_BASE_URL}edit/${item.TaskUID}`, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: handleEncodedSomeStringToBase64(pass || ''),
    },
    body: JSON.stringify(result),
  }).then((res) => {
    if (res.ok) {
      return res;
    }
    return Promise.reject(res.status);
  });
};

// добавить комментарий к карточке с задачей
export const handleSendNewComment = (item: any) => {
  const { pass } = JSON.parse(localStorage.getItem('userInfo') || '');
  return fetch(`${REACT_APP_FB_BASE_URL}newcomment/${item.UID}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: handleEncodedSomeStringToBase64(pass || ''),
    },
    body: JSON.stringify({
      Note: item.Note, // Содержание комментария
    }),
  }).then((res) => {
    if (res.ok) {
      return res;
    }
    return Promise.reject(res.status);
  });
};
