import { TaskListReqType, IPatchTaskReq } from '@shared/types';
import { baseUrl } from '@shared/constants';
import { Base64 } from 'js-base64';

const checkRes = (res: Response) => {
  if (res.ok) {
    return res;
  } else {
    return Promise.reject(res);
  }
};

export const fetchData = (url: string, method: string, token?: string | null, data?: TaskListReqType) => {
  return fetch(url, {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...(!!token && { Authorization: `${token}` }),
    },
    ...(!!data && { body: JSON.stringify(data) }),
  }).then((res) => checkRes(res));
};

export const fetchTasksList = (token: string, data: TaskListReqType): Promise<Response> => {
  return fetchData(`${baseUrl}tasks/list`, 'POST', token, data).then((res) => checkRes(res));
};

export const fetchTaskEdit = (token: string, data: IPatchTaskReq): Promise<Response> => {
  return fetchData(`${baseUrl}tasks/edit`, 'PATCH', token, data).then((res) => checkRes(res));
};

export const fetchСreateTask = (token: string, data: IPatchTaskReq): Promise<Response> => {
  return fetchData(`${baseUrl}tasks/add`, 'POST', token, data).then((res) => checkRes(res));
};

export const fetchDeleteTask = (token: string, data: IPatchTaskReq): Promise<Response> => {
  return fetchData(`${baseUrl}tasks/delete`, 'POST', token, data).then((res) => checkRes(res));
};