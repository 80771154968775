import { Editor } from '@tinymce/tinymce-react';
import styled from 'styled-components';
// import attachLogo from '@assets/logo_attachment.svg';
// import editLogo from '@assets/logo_edit.svg';
import './test.css';

const StyledLabel = styled.label`
  position: relative;
  border: none;
  border-bottom: 1px solid #bfc8e8;
  margin: ${(props) => props.theme.margin};
  padding: ${(props) => props.theme.padding};
`;

const StyledParagraph = styled.p`
  color: ${(props) => props.theme.headColor};
  font-size: 16px;
  font-family: Roboto;
  margin: 0;
  padding: 0;
`;

const StyledTextAreaButtons = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 8px;
  right: 10px;
  left: 0;
  z-index: 2;
`;

const StyledFlexDiv = styled.div`
  display: flex;
  align-items: center;
`;

const StyledSpanWithLogo = styled.span`
  display: block;
  width: 30px;
  height: 30px;
  background: url(${(props) => props.theme.logo}) center no-repeat;
`;

const StyledTextAreaNewFileDesc = styled.p`
  color: ${(props) => props.theme.headColor};
  font-size: 13px;
  font-family: Roboto;
`;

interface IStyling {
  margin?: string;
  padding?: string;
  textColor?: string;
  headColor?: string;
}

interface TValue {
  name: string;
  value: string;
}

interface IProps {
  name: string;
  value: string;
  // handleChange: ({ value }: TValue) => void;
  handleChange: ({ name, value }: TValue) => void;
  styling: IStyling;
  disabled?: boolean;
}

const CustomEditor = ({ name, value, handleChange, styling, disabled = false }: IProps) => {
  const { margin, padding, textColor, headColor } = styling;

  return (
    <StyledLabel theme={{ margin, padding }}>
      <StyledParagraph theme={{ headColor }}>{name}</StyledParagraph>

      <Editor
        // apiKey={'qagffr3pkuv17a8on1afax661irst1hbr4e6tbv888sz91jc'}
        // apiKey={'38b674168a925e3825c1ca787e233a72a0e0a66c38ea339622f26394e2dcc181'}
        apiKey={'q7e4yuslku5p2fvihl2j2wrfqq8tp7bixefe0xcs3cfwspeg'}
        value={value}
        id="me"
        disabled={disabled}
        init={{
          fontfamily: 'Roboto',
          skin: 'borderless',
          plugins: 'autoresize',
          min_height: 100,
          statusbar: false,
          menubar: false,
          toolbar: [],
          content_css: 'document',
          content_style: `
    html{
      margin: 0; 
      padding: 0 0 38px; 
      background: transparent;
    }
  
    body {
      padding: 5px ;
      margin: 0;
      background: transparent; 
      overflow-x: auto;
      cursor: auto;
      // height: 100%;
      box-shadow: none;
    }
  
    .mce-content-body p {
      margin: 0;
      text-align: justify;
      line-height: 1;
      color: ${textColor || '#363942'};
      font-size: 16px;
      font-weight: 500;
    }
  
    figure {
      outline: 3px solid #dedede;
      position: relative;
      display: inline-block
      background: transparent;
    }
    figure:hover {
      outline-color: #ffc83d
    }
    figure > figcaption {
      color: #333;
      background-color: #f7f7f7;
      text-align: center
      background: transparent;
    }
    `,
        }}
        onEditorChange={(a) => handleChange({ name: name, value: a })}
      />
      {/* {!disabled && (
        <StyledTextAreaButtons>
          <StyledFlexDiv>
            <StyledSpanWithLogo theme={{ logo: attachLogo }} />
            <StyledTextAreaNewFileDesc theme={{ headColor }}>
              Добавить файл
            </StyledTextAreaNewFileDesc>
          </StyledFlexDiv>
          <StyledSpanWithLogo theme={{ logo: editLogo }} />
        </StyledTextAreaButtons>
      )} */}
    </StyledLabel>
  );
};

export default CustomEditor;
