import tasks from './assets/tasks.svg';
import users from './assets/users.svg';
import reports from './assets/reports.svg';
import more from './assets/more.svg';

export interface SideBarInterface {
  text: string;
  path: string;
  icon?: string;
}

const menuList = (text: string, path: string, icon?: string): SideBarInterface => {
  return {
    text,
    path,
    icon: icon || '',
  };
};

export const navBarConfig: SideBarInterface[] = [
  menuList('Задачи', '/tasks', tasks),
  menuList('Пользователи', '/users', users),
  menuList('Мониторинг', '/reports', reports),
  menuList('Настройки', '/more', more),
];
