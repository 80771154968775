import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Modal,
  TextField,
  IconButton,
  Box,
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import NewProjectModal from '@widgets/NewProjectModal';
import ProjectCard from '@widgets/ProjectCard';
import { useAppSelector, useAppDispatch } from '@redux/store';
import { selectProjects, getProjects, addProject } from '@redux/slices/projects/projects';
import { IProjects } from '@shared/types';

const ProjectsPage = () => {
  const dispatch = useAppDispatch();
  const [isModalOpen, setModalOpen] = useState(false);
  const pass = localStorage.getItem('userInfo') || '';
  const projects = useAppSelector(selectProjects);

  useEffect(() => {
    // Вызываем getProjects при монтировании компонента
    dispatch(getProjects({ token: pass }));
  }, [dispatch]);

  const handleCreateProject = (newProject: IProjects) => {
    // Добавляем новый проект в Redux-хранилище
    dispatch(addProject(newProject));
  };

  // const project = projects?.Projects || [];

  return (
    <>
      <Box sx={{ width: '100vw' }}>
        <Container sx={{ marginTop: 4, p: 0 }}>
          <Grid container spacing={4}>
            {projects?.Projects?.map((project, index) => (
              <Grid item xs={12} sm={6} md={4} key={index} sx={{ width: '300px' }}>
                <ProjectCard project={project} />
              </Grid>
            ))}
            <Grid item xs={12} sm={6} md={4} sx={{ width: '300px' }}>
              <Card
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                  cursor: 'pointer',
                  backgroundColor: '#f6f8fa'
                }}
                onClick={() => setModalOpen(true)}
              >
                <CardContent sx={{ textAlign: 'center' }}>
                  <IconButton color="primary" sx={{ color: 'black' }}>
                    <AddIcon fontSize="large" sx={{ '&path': { height: '50px' } }} />
                  </IconButton>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <NewProjectModal
        open={isModalOpen}
        handleClose={() => setModalOpen(false)}
        handleCreate={handleCreateProject}
      />
    </>
  );
};

export default ProjectsPage;