import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ProjectType, UserType } from '@shared/types';
import { fetchDataPostSimple, fetchPOSTData } from 'api';

interface tasksState {
  isTasksLoading: boolean;
  isTasksRefreshing: boolean;
  projectArr: ProjectType[];
  usersArr: UserType[];
}

const initialState: tasksState = {
  isTasksLoading: true,
  isTasksRefreshing: false,
  projectArr: [],
  usersArr: [],
};

const TasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    setTasksRefreshing(state, action: PayloadAction<boolean>) {
      return { ...state, isTasksRefreshing: action.payload };
    },
    setProjects(state, action: PayloadAction<ProjectType[]>) {
      return { ...state, projectArr: action.payload };
    },
    setUsers(state, action: PayloadAction<UserType[]>) {
      return { ...state, usersArr: action.payload };
    },
  },
});

export const { setProjects, setUsers, setTasksRefreshing } = TasksSlice.actions;

export default TasksSlice.reducer;

export const fetchProjects = createAsyncThunk('tasks/fetchProjects', async (_, { dispatch }) => {
  await fetchPOSTData('projects/list', (res: any) => dispatch(setProjects(res.Projects)));
});

export const refreshTasks = createAsyncThunk('tasks/refreshTasks', (_, { dispatch }) => {
  dispatch(setTasksRefreshing(true));
  fetchDataPostSimple('tasks/list')
    .then((res) => console.log(res))
    .catch((err) => console.error(err.message))
    .finally(() => dispatch(setTasksRefreshing(false)));
});

export const fetchUsers = createAsyncThunk('tasks/fetchUsers', async (_, { dispatch }) => {
  await fetchPOSTData('users/list', (res: any) => dispatch(setUsers(res)));
});
