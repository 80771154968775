import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { Button, Typography, Box, Modal, TextField, CircularProgress } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { selectTasksList, updateTaskStatus, selectTasksListStatus } from '@redux/slices/tasksList/tasksList';
import TaskComponent from '@components/TaskComponent/TaskComponent';
import { TaskListResType } from '@shared/types';
// import CustomScrollbar from '../CustomScrollbar/CustomScrollbar';
// import { patchTaskEdit } from '@redux/slices/taskEdit/taskEdit';
import { getTasksList, patchTaskEdit, createTask } from '@redux/slices/tasksList/tasksList';
import {
  boardColumnsStyle,
  boardColumnStyle,
  dividerBoardStyle,
  pointBoardStyle,
  tasksBoardStyle,
  pointLengthStyle,
} from './index.style';
import ModalCreateTask from '@widgets/ModalCreateTask';

const TasksBoard = () => {
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentStatus, setCurrentStatus] = useState('');
  const [newTaskName, setNewTaskName] = useState('');
  const tasksList = useAppSelector(selectTasksList);
  const dispatch = useAppDispatch();
  const pass = localStorage.getItem('userInfo') || '';
  const initialColumns: Record<string, TaskListResType[]> = {
    Назначена: [],
    'В работе': [],
    Выполнена: [],
    Отменена: [],
    Черновик: [],
  };
  const location = useLocation();
  const UID = location.pathname.split('/').pop();
  const status = useAppSelector(selectTasksListStatus);

  const handleOpenModal = (statusString) => {
    setCurrentStatus(statusString);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setNewTaskName('');
  };

  const handleCreateTask = async () => {
    const taskBody = {
      Subject: newTaskName,
      Status: currentStatus,
      Project: {
        UID: UID
      }
    }

    if (newTaskName.trim()) {
      try {
        await dispatch(createTask({ token: pass, data: taskBody }));
        handleCloseModal(); // Закрываем модальное окно после успешного создания
      } catch (err) {
        console.error('Error creating task:', err);
      }
    }
  };

  // const handleCreateTask = () => {
  //   // if (newTaskName.trim()) {
  //   //   createTask(currentStatus, newTaskName)
  //   //     .then(() => {
  //   //       handleCloseModal(); // Закрываем модальное окно после успешного создания
  //   //     })
  //   //     .catch((err) => console.error('Error creating task:', err));
  //   // }
  // };

  useEffect(() => {
    const fetchTasks = async () => {
      setLoading(true); // Начинаем загрузку
      try {
        await dispatch(getTasksList({ token: pass, data: { Project: UID } }));
      } catch (error) {
        console.error('Error fetching tasks:', error);
      } finally {
        setLoading(false); // Завершаем загрузку
      }
    };

    if (UID && pass) {
      fetchTasks();
    }
  }, [location]);

  // Используем useState для каждой колонки
  const [columns, setColumns] = useState(initialColumns);

  const organizeTasks = () => {
    const updatedColumns: Record<string, TaskListResType[]> = { ...initialColumns };

    tasksList.forEach((task) => {
      const status = task.Status;

      if (!updatedColumns[status]) {
        updatedColumns[status] = [];
      }

      // Проверяем, есть ли такая задача в колонке
      if (!updatedColumns[status].some((t) => t.UID === task.UID)) {
        updatedColumns[status].push(task);
      }
    });

    // Обновляем только изменившиеся колонки
    setColumns((prevColumns) => {
      const nextColumns: Record<string, TaskListResType[]> = { ...prevColumns };

      Object.keys(updatedColumns).forEach((status) => {
        if (JSON.stringify(prevColumns[status]) !== JSON.stringify(updatedColumns[status])) {
          nextColumns[status] = updatedColumns[status];
        }
      });

      return nextColumns;
    });
  };

  useEffect(() => {
    organizeTasks();
  }, [tasksList]);

  const onDragEnd = async (result: DropResult) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    // Клонируем стейт для предотвращения мутации
    const updatedColumns = { ...columns };
    const sourceColumn = updatedColumns[source.droppableId];
    const destinationColumn = updatedColumns[destination.droppableId];

    // Удаляем элемент из начальной колонки
    const [movedTask] = sourceColumn.splice(source.index, 1);

    // Вставляем элемент в конечную колонку
    destinationColumn.splice(destination.index, 0, movedTask);

    // Обновляем стейт
    setColumns(updatedColumns);

    if (source.droppableId !== destination.droppableId) {
      // Изменяем статус задачи
      const updatedTask = tasksList.find((task) => task.UID === movedTask.UID);

      if (updatedTask) {
        try {
          await dispatch(updateTaskStatus({ ...updatedTask, Status: destination.droppableId }));
          await dispatch(
            patchTaskEdit({
              token: pass,
              data: { UID: updatedTask.UID, Status: destination.droppableId },
            })
          );
        } catch (error) {
          console.error('Error updating task status:', error);
        }
      }
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Box sx={boardColumnsStyle}>
        {Object.entries(columns)
          .filter(([statusString, tasks]) => statusString)
          .map(([statusString, tasks], columnIndex) => (
            <Box id="idForModal" key={statusString} sx={boardColumnStyle}>
              <Droppable droppableId={statusString || `default-${columnIndex}`} key={statusString}>
                {(provided) => (
                  <Box
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    sx={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', padding: '8px 16px' }}>
                      <Box sx={pointBoardStyle(statusString)} />
                      <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
                        {statusString}
                      </Typography>
                      <Box sx={pointLengthStyle}>{tasks.length}</Box>
                    </Box>
                    {/* <Divider sx={dividerBoardStyle(statusString)} /> */}
                    <Box sx={tasksBoardStyle}>
                      {tasks.map((task, index) => (
                        <Draggable
                          key={task.UID}
                          draggableId={task.UID || `default-${index}`}
                          index={index}
                        >
                          {(provided) => (
                            <Box
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                            >
                              <TaskComponent task={task} />
                            </Box>
                          )}
                        </Draggable>
                      ))}
                    </Box>
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
              <Box sx={{
                p: '8px 16px',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'rgba(208, 215, 222, 0.32)',
                },
              }}
                onClick={() => handleOpenModal(statusString)}
              >
                <Typography>+ Add Task</Typography>
              </Box>
            </Box>
          ))}
      </Box>
      <ModalCreateTask
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        taskName={newTaskName}
        onTaskNameChange={setNewTaskName}
        onTaskCreate={handleCreateTask}
      />
    </DragDropContext>
  );
};

export default TasksBoard;
