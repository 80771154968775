import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { CheckListItemType, NewCheckItemType } from '@shared/types';
import moreLogoMobile from './assets/more.svg';
import moreLogoDesktop from './assets/more_desktop.svg';
import closeLogo from './assets/close.svg';
import toTaskLogo from './assets/toTask.svg';
import upLogo from './assets/up.svg';
import downLogo from './assets/down.svg';
import deleteLogo from './assets/delete.svg';

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  position: relative;
`;
const StyledHeading = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: ${(props) => (props.theme.pointer ? 'pointer' : 'default')};
  transition: all 0.3s ease;
  width: 100%;

  &:hover {
    opacity: ${(props) => (props.theme.pointer ? '0.8' : '1')};
  }
`;

const StyledCheckboxInput = styled.input``;

const StyledTextInput = styled.input`
  color: var(--checkItemTextColorMobile, #363942);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 16px */
  letter-spacing: 0.16px;
  background: transparent;
  width: 100%;

  // @media screen and (min-width: 1000px) {
  //   color: var(--checkItemTextColorDesktop, #ffffff);
  // }
`;

const StyledMoreButton = styled.button`
  width: 47px;
  height: 29px;
  border-radius: 4px;
  transition: all 0.3s ease;
  background-image: url(${moreLogoMobile});
  background-position: center;
  background-repeat: no-repeat;
  flex-shrink: 0;

  &:hover {
    border-radius: 5px;
    background-color: #e5eafc;
  }

  // @media screen and (min-width: 1000px) {
  //   background-image: url(${moreLogoDesktop});
  // }
`;

const StyledModal = styled.div`
  position: absolute;
  padding: 13px 11px 21px 24px;
  background: #fff;
  border: 1px solid #bfc8e8;
  border-radius: 5px;
  z-index: 1;
  right: 0;
  top: calc(100% - 10px);
  width: 100%;
  max-width: 400px;
`;

const StyledModalItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;
  margin: 10px 0 0;
`;

const StyledModalHeadingText = styled.p`
  color: #bfc8e8;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const StyledCloseButton = styled.button`
  width: 21px;
  height: 21px;
  transition: all 0.3s ease;
  background-image: url(${closeLogo});
  background-position: center;
  background-repeat: no-repeat;

  &:hover {
    opacity: 0.8;
  }
`;

const StyledSpanWithLogo = styled.span`
  display: block;
  width: 23px;
  height: 23px;
  background: url(${(props) => props.theme.logo}) center no-repeat;
`;

const StyledText = styled.p`
  color: ${(props) => props.theme.textColor || 'var(--checkItemTextColorMobile, #363942)'};
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 16px */
  letter-spacing: 0.16px;

  @media screen and (min-width: 1000px) {
    color: ${(props) => props.theme.textColor || 'var(--checkItemTextColorDesktop, #FFFFFF)'};
  }
`;

interface ICheckItemProps {
  checkItemInfo: NewCheckItemType | CheckListItemType;
  onClick: () => void;
  canConvertToTask: boolean;
  createTask: () => void;
  onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  canMoveUp: boolean;
  handleMoveUp: () => void;
  canMoveDown: boolean;
  handleMoveDown: () => void;
  handleDelete: () => void;
  sendCheckListEditRequest: (uid: string, CheckNumber: number, isChecked: boolean) => Promise<void>;
  taskId: string;
}

export const CheckItem = ({
  checkItemInfo,
  onClick,
  canConvertToTask,
  createTask,
  onChange,
  canMoveUp,
  handleMoveUp,
  canMoveDown,
  handleMoveDown,
  handleDelete,
  sendCheckListEditRequest,
  taskId,
}: ICheckItemProps) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(checkItemInfo.Checked);
  const rootRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isModalOpen) {
      window.addEventListener('click', onClickHandler);
    }
    return () => window.removeEventListener('click', onClickHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  // закрываем по клику вне модального окна
  const onClickHandler = (e: MouseEvent) => {
    e.target instanceof Node && !rootRef.current?.contains(e.target) && setModalOpen(false);
  };

  const handleChange = () => {
    setIsChecked((prevState) => {
      const newState = !prevState;
      sendCheckListEditRequest(taskId, checkItemInfo.CheckNumber, newState);
      return newState;
    });
    onClick();
  };

  const onOptionClick = (v: 'toTask' | 'up' | 'down' | 'delete') => {
    setModalOpen(false);
    switch (v) {
      case 'toTask':
        createTask();
        break;
      case 'up':
        handleMoveUp();
        break;
      case 'down':
        handleMoveDown();
        break;
      default:
        handleDelete();
        break;
    }
  };

  return (
    <StyledContainer ref={rootRef}>
      <StyledHeading>
        <StyledCheckboxInput type="checkbox" onChange={handleChange} checked={isChecked} />
        {'SubTaskUID' in checkItemInfo && !!checkItemInfo.SubTaskUID ? (
          <Link to={`/tasks/${checkItemInfo.SubTaskUID}`}>
            <StyledText theme={{ textColor: '#3F52B6' }}>{checkItemInfo.CheckName}</StyledText>
          </Link>
        ) : (
          <StyledTextInput type="text" value={checkItemInfo.CheckName} onChange={onChange} />
        )}
      </StyledHeading>
      <StyledMoreButton onClick={() => setModalOpen(!isModalOpen)} />
      {isModalOpen && (
        <StyledModal>
          <StyledContainer>
            <StyledModalHeadingText>Действия элемента</StyledModalHeadingText>
            <StyledCloseButton onClick={() => setModalOpen(!isModalOpen)} />
          </StyledContainer>
          <StyledModalItems>
            {canConvertToTask && (
              <StyledHeading onClick={() => onOptionClick('toTask')} theme={{ pointer: true }}>
                <StyledSpanWithLogo theme={{ logo: toTaskLogo }} />
                <StyledText theme={{ textColor: 'var(--checkItemTextColorMobile, #363942)' }}>
                  Преобразовать в задачу
                </StyledText>
              </StyledHeading>
            )}
            {canMoveUp && (
              <StyledHeading onClick={() => onOptionClick('up')} theme={{ pointer: true }}>
                <StyledSpanWithLogo theme={{ logo: upLogo }} />
                <StyledText theme={{ textColor: 'var(--checkItemTextColorMobile, #363942)' }}>
                  Переместить вверх
                </StyledText>
              </StyledHeading>
            )}
            {canMoveDown && (
              <StyledHeading onClick={() => onOptionClick('down')} theme={{ pointer: true }}>
                <StyledSpanWithLogo theme={{ logo: downLogo }} />
                <StyledText theme={{ textColor: 'var(--checkItemTextColorMobile, #363942)' }}>
                  Переместить вниз
                </StyledText>
              </StyledHeading>
            )}
            <StyledHeading onClick={() => onOptionClick('delete')} theme={{ pointer: true }}>
              <StyledSpanWithLogo theme={{ logo: deleteLogo }} />
              <StyledText theme={{ textColor: 'var(--checkItemTextColorMobile, #363942)' }}>
                Удалить
              </StyledText>
            </StyledHeading>
          </StyledModalItems>
        </StyledModal>
      )}
    </StyledContainer>
  );
};
