import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TaskListResType } from '@shared/types';
import { Box, Typography } from '@mui/material';
import AvatarIcon from '@assets/dashboard/mini-avatar.svg';
import dateicon from '@assets/dashboard/date-time.svg';
import closeEyeIcon from '@assets/auth/eye_closed.svg';
import openEyeIcon from '@assets/auth/eye_open.svg';
import ModalTask from '@components/Modal/ModalTask/ModalTask';

interface TaskComponentProps {
  task: TaskListResType;
}

const TaskComponent: React.FC<TaskComponentProps> = ({ task }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  // В обработчике открытия модального окна вы можете установить anchorEl
  const handleOpenModal = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsModalOpen(true);
  };

  // В обработчике закрытия модального окна вы можете сбросить anchorEl
  const handleCloseModal = () => {
    setAnchorEl(null);
    setIsModalOpen(false);
  };

  function formatDate(inputDate: string) {
    const date = new Date(inputDate);

    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString().slice(-2);

    // return `${hours}:${minutes} ${day}.${month}.${year}`;
    return `${day}.${month}.${year}`;
  }

  return (
    <>
      <Box
        sx={{
          maxWidth: '378px',
          backgroundColor: '#fff',
          boxShadow: '0px 3px 6px rgba(140, 149, 159, 0.15)',
          border: '1px solid #d0d7de',
          borderRadius: '8px',
          p: '8px 12px 12px',
          fontFamily: 'Roboto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'space-between',
          }}
        >
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', gap: '8px' }}>
                {task.Project?.Name && (
                  <Typography
                    sx={{
                      fontSize: '12px',
                      lineHeight: '12px',
                      fontWeight: '400',
                      color: 'rgba(54, 57, 66, 0.65)',
                      marginBottom: '8px',
                    }}
                  >
                    {task.Project.Name}
                  </Typography>
                )}
                <Typography
                  sx={{
                    fontSize: '12px',
                    lineHeight: '12px',
                    fontWeight: '400',
                    color: 'rgba(54, 57, 66, 0.65)',
                    marginBottom: '8px',
                  }}
                >
                  #{parseInt(task.Number).toString()}
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontSize: '12px',
                  lineHeight: '12px',
                  fontWeight: '400',
                  color: 'rgba(54, 57, 66, 0.65)',
                  marginBottom: '8px',
                }}
              >
                {formatDate(task.Date)}
              </Typography>
            </Box>
            <Typography
              sx={{ fontSize: '14px', lineHeight: '18px', fontWeight: '400' }}
              onClick={handleOpenModal}
            >
              {task.Subject}
            </Typography>
          </Box>
        </Box>
      </Box>
      {isModalOpen && <ModalTask task={task} onClose={handleCloseModal} />}
    </>
  );
};

export default TaskComponent;
