import { CheckItem } from '@components/UI/CheckItem';
import { CheckListItemType } from '@shared/types';

const SubTaskList = ({
  styles,
  taskDesc,
  setTaskDesk,
  handleChangeDesk,
  handleChangeCheckList,
  sendCheckListEditRequest,
}) => {
  return (
    <div className={styles.subtasksLabel}>
      <p className={styles.text}>Добавить чек-лист</p>
      <div className={styles.subtasksItems}>
        {!!taskDesc.CheckList.length &&
          taskDesc.CheckList.map((item, index) => (
            <CheckItem
              taskId={taskDesc.UID}
              checkItemInfo={item}
              sendCheckListEditRequest={sendCheckListEditRequest}
              onClick={() =>
                handleChangeDesk({
                  ...taskDesc,
                  CheckList: handleChangeCheckList(
                    taskDesc.CheckList,
                    'onClick',
                    '',
                    index
                  ) as CheckListItemType[],
                })
              }
              onChange={(e) =>
                setTaskDesk({
                  ...taskDesc,
                  CheckList: handleChangeCheckList(
                    taskDesc.CheckList,
                    'onChange',
                    e.target.value,
                    index
                  ) as CheckListItemType[],
                })
              }
              canMoveUp={index !== 0}
              handleMoveUp={() =>
                setTaskDesk({
                  ...taskDesc,
                  CheckList: handleChangeCheckList(
                    taskDesc.CheckList,
                    'up',
                    '',
                    index
                  ) as CheckListItemType[],
                })
              }
              canMoveDown={taskDesc.CheckList.length - 1 !== index}
              handleMoveDown={() =>
                setTaskDesk({
                  ...taskDesc,
                  CheckList: handleChangeCheckList(
                    taskDesc.CheckList,
                    'down',
                    '',
                    index
                  ) as CheckListItemType[],
                })
              }
              handleDelete={() =>
                setTaskDesk({
                  ...taskDesc,
                  CheckList: handleChangeCheckList(
                    taskDesc.CheckList,
                    'delete',
                    '',
                    index
                  ) as CheckListItemType[],
                })
              }
              key={item.CheckNumber}
              canConvertToTask={false}
              createTask={() => console.log(1)}
            />
          ))}
      </div>
    </div>
  );
};

export default SubTaskList;
