import { Box, Typography, Backdrop, Divider, IconButton, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { TaskListResType } from '@shared/types';
import { useAppDispatch } from '@redux/store';
import { deleteTask } from '@redux/slices/tasksList/tasksList';

interface TaskPageOpenProps {
  task: TaskListResType;
  onClose: () => void;
}

const TaskPageOpen: React.FC<TaskPageOpenProps> = ({ task, onClose }) => {
  const dispatch = useAppDispatch();
  const pass = localStorage.getItem('userInfo') || '';


  const handleDeleteTask = () => {
    dispatch(deleteTask({ token: pass, data: { UID: task.UID} }));
    onClose()
  };

  return (
    <Box sx={{ paddingTop: '16px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '0 24px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Typography sx={{ fontSize: '32px', fontWeight: '400', lineHeight: '40px', marginRight: '8px' }}>
            {task.Subject}
          </Typography>
          <Typography sx={{ fontSize: '32px', fontWeight: '300', lineHeight: '40px', color: 'rgba(54, 57, 66, 0.65)' }}>
            #{parseInt(task.Number).toString()}
          </Typography>
        </Box>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Typography sx={{ padding: '12px 0 12px 24px', fontSize: '12px', color: 'rgba(54, 57, 66, 0.65)' }}>
        {task.Project.Name}
      </Typography>
      <Divider sx={{ margin: '0 24px' }} />
      <Box sx={{ padding: '16px 24px' }}>
        <Button variant="contained" color="error" onClick={handleDeleteTask}>
          Delete Task
        </Button>
      </Box>
    </Box>
  );
};

export default TaskPageOpen;
