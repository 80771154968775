import { baseUrl } from '@shared/constants';
import { Base64 } from 'js-base64';

const checkRes = (res: Response) => {
  if (res.ok) {
    return res;
  } else {
    return Promise.reject(res);
  }
};

export const fetchData = (url: string, method: string, token?: string, body?: {}) => {
  return fetch(url, {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...(!!token && { Authorization: `${token}` }),
    },
    ...(!!body && { body: JSON.stringify(body) }),
  }).then((res) => checkRes(res));
};

export const fetchProjects = (token: string): Promise<Response> => {
  return fetchData(`${baseUrl}projects/list`, 'POST', token).then((res) => checkRes(res));
};

export const fetchAddProjects = (token: string, body: {}): Promise<Response> => {
  return fetchData(`${baseUrl}projects/add_project`, 'POST', token, body).then((res) => checkRes(res));
};

export const fetchDeleteProjects = (token: string, body: {}): Promise<Response> => {
  return fetchData(`${baseUrl}projects/delete`, 'POST', token, body).then((res) => checkRes(res));
};
