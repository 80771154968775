import styles from './styles.module.scss';
import { SimpleButton } from '@components/UI/Button';

const TaskActions = ({ isChanged, onCancel, onSave }) => {
  return (
    isChanged && (
      <div className={styles.buttons}>
        <SimpleButton text={'Отменить'} secondary handleClick={onCancel} />
        <SimpleButton text={'Сохранить'} handleClick={onSave} />
      </div>
    )
  );
};

export default TaskActions;
