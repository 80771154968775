import styles from './styles.module.scss';
import { TasksModal } from '@widgets/Lists';
import { PageLayout } from '@entities/Layouts';
import { useContext, useEffect, useRef, useState } from 'react';
import { AuthContext } from '@contexts/CurrUserContext';
import { TasksDispatch } from '@redux/store';
import { TaskDescDefault } from '@shared/constants';
import { TaskDescType, TaskCommentType } from '@shared/types';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router';
import { fetchDataPatchSimple, fetchDataPostSimple } from 'api';
import { handleNewTextComment } from '@shared/hooks';
import Task from '@components/Tasks/Task';
import { SimpleButton } from '@components/UI/Button';
import { ChatContainer } from '@components/UI/NewComment';
import { refreshTasks } from '@redux/slices/tasksSlice';
import { Comment } from '@components/UI/Comment';
import ProtectedRoute from '@shared/HOC/ProtectedRoute';
import { CreateNewTaskButton } from '@shared/UI/Buttons';
import { TaskActions } from '@widgets/Components';

const TaskPage = () => {
  const [readOnly, setReadOnly] = useState(true);
  const [taskDesc, setTaskDesk] = useState<TaskDescType>(TaskDescDefault);
  const [commArr, setCommArr] = useState<TaskCommentType[]>([]);
  const [newComment, setNewComment] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { userInfo, isLogged } = useContext(AuthContext);
  const { task } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<TasksDispatch>();
  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    setIsLoading(true);
    !!task &&
      isLogged &&
      fetchDataPostSimple('tasks/open', { UID: task })
        .then((res) => setTaskDesk({ ...res, isChanged: false }))
        .then(() => fetchDataPostSimple('tasks/comments', { UID: task }))
        .then((res) => setCommArr(res))
        // .catch(() => console.log('/404'))
        .catch(() => navigate('/404'))
        .finally(() => setIsLoading(false));
  }, [navigate, task, isLogged]);

  // useEffect(() => {
  //   messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  // }, [commArr]);

  const handleEditApp = () => {
    setIsLoading(true);
    const reqBody = {
      UID: taskDesc.UID,
      Subject: taskDesc.Subject,
      Note: taskDesc.Note,
      Status: taskDesc.Status,
      Project: taskDesc.Project.UID,
      DeadLine: taskDesc.DeadLine,
    };
    !!taskDesc.Executor.UID && Object.assign(reqBody, { Executor: taskDesc.Executor.UID });
    fetchDataPatchSimple('tasks/edit', reqBody)
      .then(() => {
        // setTypeResultModal(ModalType['edit']);
        // setResultModal(true);
        setTaskDesk((prevTaskDesc) => ({ ...prevTaskDesc, isChanged: true }));
        dispatch(refreshTasks());
      })
      .catch((err) => {
        console.error(err);
        // setTypeResultModal(ModalType['err']);
        // setResultModal(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleNewComment = () => {
    handleNewTextComment(
      newComment,
      setNewComment,
      setIsLoading,
      taskDesc.UID,
      setCommArr,
      commArr,
      userInfo.UID,
      userInfo.Name
    );
  };

  return (
    <PageLayout
      heading={`Задача #${+taskDesc.Number}`}
      canGoBack={false}
      headerChildren={
        window.innerWidth > 1000 && !readOnly ? <CreateNewTaskButton link={'/tasks/new'} /> : <></>
      }
    >
      <>
        <div className={styles.column}>
          <TasksModal setReadOnly={setReadOnly} />
        </div>
        <div className={styles.additional}>
          <div className={styles.container}>
            <div className={styles.comments}>
              <Comment
                imgLink={''}
                user={taskDesc.Initiator}
                title={taskDesc.Note}
                date={taskDesc.Date}
                isAdmin={false}
                styling={{ headColor: '#363942' }}
              />
              {commArr &&
                commArr.map((comm, i) => (
                  <Comment
                    imgLink={''}
                    user={comm.SentFrom}
                    title={comm.Note}
                    date={comm.CommentDateTime}
                    key={i}
                    isAdmin={comm.SentFrom.UID === userInfo.UID}
                    styling={{ headColor: '#363942' }}
                  />
                ))}
              <div ref={messagesEndRef} />
            </div>
            <div className={styles.chatContainer}>
              <ChatContainer
                // handleUploadFile={handleSetNewFileComment}
                value={newComment}
                handleChange={(e) => setNewComment(e.target.value)}
                handleSubmit={() => handleNewComment()}
                isLoading={isLoading}
              />
            </div>
          </div>
          <div className={styles.info}>
            <Task taskDesc={taskDesc} setTaskDesk={setTaskDesk} />
            <TaskActions
              isChanged={taskDesc.isChanged}
              onCancel={() => window.location.reload()}
              onSave={handleEditApp}
            />
          </div>
        </div>
      </>
    </PageLayout>
  );
};

export default ProtectedRoute(TaskPage);
