import React, { useRef } from 'react';
import { Box, Typography, Backdrop } from '@mui/material';
import ReactDOM from 'react-dom';
import { TaskListResType } from '@shared/types';
import TaskPageOpen from '@components/TaskPageOpen/TaskPageOpen';

interface ModalTaskProps {
  task: TaskListResType;
  onClose: () => void;
}

const ModalTask: React.FC<ModalTaskProps> = ({ task, onClose }) => {

  function formatDate(inputDate: string) {
    const date = new Date(inputDate);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString().slice(-2);
    return `${day}.${month}.${year}`;
  }

  function handleClickInsideModal(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (event.target === event.currentTarget) {
      // Если клик произошел вне модального окна, закрываем его
      onClose();
    }
  }

  return ReactDOM.createPortal(
    <Backdrop open={true} onClick={handleClickInsideModal}>
      <Box
        sx={{
          position: 'absolute',
          bottom: 0, // Выравниваем по нижнему краю экрана
          right: 0, // Выравниваем по правому краю экрана
          transform: 'translate(100%, 0)', // Сдвигаем вправо для прижатия к правому краю
          width: '80%',
          height: 'calc(100vh - 70px)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          animation: 'slide-in 0.3s forwards', // Добавили анимацию slide-in
          zIndex: '9999',
          '@keyframes slide-in': {
            '0%': { transform: 'translateX(100%)' },
            '100%': { transform: 'translateX(0)' },
          },
          borderTopLeftRadius: '16px',
          borderBottomLeftRadius: '16px',
        }}
      >
        <TaskPageOpen task={task} onClose={onClose}/>
      </Box>
    </Backdrop>,
    document.getElementById('idForModal')
  );
};

export default ModalTask;
