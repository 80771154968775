import { useNavigate } from 'react-router';
import Button, { ButtonProps } from '@mui/material/Button';
import AddIcon from './assets/new.svg';

type TButtonProps = {
  link: string;
} & ButtonProps;

export default function CreateNewTaskButton({ link, ...otherProps }: TButtonProps) {
  const navigate = useNavigate();

  return (
    <Button
      sx={{
        width: '28px',
        height: '28px',
        cursor: 'pointer',
        transition: 'all 0.3s ease',

        ':hover': {
          opacity: 0.8,
        },

        '@media(min-width: 1000px)': {
          width: '60px',
          height: '60px',
        },
      }}
      startIcon={<span style={{ background: `url(${AddIcon}) center no-repeat`, width: '28px', height: '28px' }} />}
      onClick={() => navigate(link)}
      {...otherProps}
    ></Button>
  );
}
