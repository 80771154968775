export const boardColumnsStyle = {
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
  padding: '15px',
  overflowX: 'auto',
  '&::-webkit-scrollbar': {
    width: '12px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
    borderRadius: '8px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#555',
  },
};

export const boardColumnStyle = {
  display: 'flex',
  flexDirection: 'column',
  // borderRight: '1px solid #ccc',
  borderRadius: '10px',
  border: '1px solid #d0d7de',
  backgroundColor: '#F4F4F4',
  // padding: '8px 8px',
  minWidth: '316px',
};

export const pointBoardStyle = (statusString: string) => ({
  width: '16px',
  height: '16px',
  borderRadius: '50%',
  border: '2px solid', // Яркий бордер
  borderColor:
    statusString === 'В работе'
      ? 'rgba(0, 0, 255, 1)' // Пример яркого цвета бордера
      : statusString === 'Выполнена' || statusString === 'Завершена'
        ? 'rgba(0, 128, 0, 1)' // Пример яркого цвета бордера
        : statusString === 'Назначена'
          ? 'rgba(255, 165, 0, 1)' // Пример яркого цвета бордера
          : 'rgba(128, 128, 128, 1)', // Пример яркого цвета бордера
  backgroundColor:
    statusString === 'В работе'
      ? 'rgba(0, 0, 255, .15)' // Пример яркого цвета бордера
      : statusString === 'Выполнена' || statusString === 'Завершена'
        ? 'rgba(0, 128, 0, .15)' // Пример яркого цвета бордера
        : statusString === 'Назначена'
          ? 'rgba(255, 165, 0, .15)' // Пример яркого цвета бордера
          : 'rgba(128, 128, 128, .15)', // Пример яркого цвета бордера
  marginRight: '8px',
});

export const dividerBoardStyle = (statusString: string) => ({
  margin: '23px 0 18px 0',
  height: '2px',
  backgroundColor:
    statusString === 'В работе'
      ? 'blue'
      : statusString === 'Выполнена' || statusString === 'Завершена'
        ? 'green'
        : statusString === 'Назначена'
          ? 'orange'
          : 'grey',
});

export const tasksBoardStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  height: 'calc(100vh - 235px)',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '12px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
    borderRadius: '8px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#555',
  },
  pl: '8px',
  pr: '8px',
  pt: '5px',
};

export const pointLengthStyle = {
  width: '18px',
  height: '18px',
  borderRadius: '50%',
  backgroundColor: 'rgba(175, 184, 193, 0.2)',
  marginLeft: '8px',
  fontFamily: 'Roboto',
  fontSize: '13px',
  fontWeight: '500',
  lineHeight: '15px',
  color: '#7F7F7F',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};
