import styles from './styles.module.scss';
import { TasksList } from '@widgets/Lists';
import { PageLayout } from '@entities/Layouts';
import { UserStartPage } from '@widgets/Components';
import { useState } from 'react';
import ProtectedRoute from '@shared/HOC/ProtectedRoute';

const StartPage = () => {
  const [readOnly, setReadOnly] = useState(true);
  return (
    <>
      {window.innerWidth > 1000 && (
        <div className={styles.column}>
          <TasksList setReadOnly={setReadOnly} />
        </div>
      )}

      <div className={styles.additional}>
        <UserStartPage />
      </div>
    </>
  );
};

export default ProtectedRoute(StartPage);
