import React, { useContext, useLayoutEffect, useState } from 'react';
import { Routes, Route, RouterProvider } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { AuthContext } from '@contexts/CurrUserContext';
import { handleAuth } from 'api';
import { TasksDispatch } from '@redux/store';
import { fetchProjects, fetchUsers } from '@redux/slices/tasksSlice';
import { requestForToken } from '../../firebase';
import { routes } from './routes/paths';

import AuthPage from 'pages/AuthPage/AuthPage';
import NoDeskPage from 'pages/NotFoundPage/NoDeskPage/NoDeskPage';
import NoMobilePage from 'pages/NotFoundPage/NoMobilePage/NoMobilePage';
import { TasksPage } from 'pages';
import StartPage from 'pages/StartPage';
import TaskPage from 'pages/TaskPage';
import NewTaskPage from 'pages/NewTaskPage';

function App() {
  const [isMobile, setIsMobile] = useState(document.documentElement.scrollWidth < 1000);
  const { login, authLogged, logout } = useContext(AuthContext);
  const dispatch = useDispatch<TasksDispatch>();
  // const { notificationsNumber } = useSelector((state: RootState) => state.notifications);

  // слушатель изменения ширины страницы
  useLayoutEffect(() => {
    const handlePageWidth = () => {
      setIsMobile(document.documentElement.scrollWidth < 990);
    };
    handlePageWidth();
    window.addEventListener('resize', handlePageWidth);
    return () => window.removeEventListener('resize', handlePageWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // срабатывает при перезагрузке страницы и прокидывает данные в общий контекст
  useLayoutEffect(() => {
    const pass = localStorage.getItem('userInfo');
    !!pass &&
      handleAuth(pass)
        .then((res) => login(res))
        .then(() => dispatch(fetchProjects()))
        .then(() => dispatch(fetchUsers()))
        .then(() => requestForToken())
        .catch((err) => (err === 401 || err === 403 ? logout() : console.error(err)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authLogged]);

  return <RouterProvider router={routes} />;
}

export default App;
