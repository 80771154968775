import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import cn from 'classnames';

import styles from './styles.module.scss';
import companyLogo from '@assets/header/company_logo.png';
import { AuthContext } from '@contexts/CurrUserContext';
import { useSelector } from 'react-redux';
import { RootState } from '@redux/store';
import logo_rixap from './assets/logo_rixap.png';
import { Button, Drawer, List, ListItem, ListItemText, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ModalNavBar from '@widgets/ModalNavBar';
import { useAppSelector } from '@redux/store';
import { selectProjects } from '@redux/slices/projects/projects';

interface IProps {
  canGoBack?: boolean;
  text?: string;
  children?: React.ReactElement;
}

const Header: React.FC<IProps> = ({ canGoBack, text, children, ...otherProps }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
  const navigate = useNavigate();
  const location = useLocation();
  const { UID } = useParams();
  const projects = useAppSelector(selectProjects);
  const { isLogged, logout } = useContext(AuthContext);
  const { notificationsNumber } = useSelector((state: RootState) => state.notifications);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  // слушатель изменения ширины страницы
  useLayoutEffect(() => {
    const handlePageWidth = () => {
      setIsMobile(window.innerWidth < 990);
    };
    handlePageWidth();
    window.addEventListener('resize', handlePageWidth);
    return () => window.removeEventListener('resize', handlePageWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Функция для получения названия проекта по UID
  const getProjectTitle = (UID: string) => {
    const project = projects.Projects.find(project => project.UID === UID);
    return project ? project.Name : '';
  };

  // const paths = location.pathname.split('/').filter(Boolean);
  const generateBreadcrumbs = (pathnames: string[]) => {
    const breadcrumbs = [
      { name: 'Проекты', to: '/projects' },
    ];
  
    let accumulatedPath = '/projects';
  
    pathnames.forEach((pathname, index) => {
      if (pathname && pathname !== 'projects') {
        accumulatedPath += `/${pathname}`;
        
        const title = index === 0 ? 'Проекты' : getProjectTitle(pathname);
        breadcrumbs.push({
          name: title,
          to: accumulatedPath
        });
      }
    });
  
    return breadcrumbs;
  };
  const pathnames = location.pathname.split('/').filter(x => x);
  const breadcrumbs = generateBreadcrumbs(pathnames);


  return (
    <header className={styles.header} {...otherProps}>
      {isMobile ? (
        // для мобильной версии
        <>
          {canGoBack && <span className={styles.arrow} onClick={() => navigate(-1)} />}
          <div>
            {text ? (
              <h2 className={styles.title}>{text}</h2>
            ) : (
              <img className={styles.logo} src={companyLogo} alt="company logo" />
            )}
          </div>
          {isLogged && (
            <Link
              to={'/notifications'}
              className={cn(styles.notification, notificationsNumber && styles.notification_active)}
            />
          )}
          {children || <></>}
        </>
      ) : (
        // для десктопа
        <>
          <IconButton sx={{ border: '1px solid #d0d7de', borderRadius: '15%', p: '3px' }} onClick={toggleDrawer(true)}>
            <MenuIcon sx={{ width: '24px', height: '24px' }} />
          </IconButton>
          <Link to={'/'} style={{ marginLeft: '10px' }}>
            {/* <h2 className={styles.subtitle}>{text}</h2> */}
            <img src={logo_rixap} alt="alt" />
          </Link>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {breadcrumbs.map(({ name, to }, index) => (
              <React.Fragment key={to}>
                {index > 0 && <span className={styles.separator}> / </span>}
                <Link to={to} className={styles.breadcrumbLink}>{name}</Link>
              </React.Fragment>
            ))}
          </div>
          {children || <></>}
          <div className={styles.attr_desk}>
            {/* <Link to={'/notifications'} className={styles.link}>
              <span
                className={cn(
                  styles.notification,
                  !!notificationsNumber && styles.notification_active
                )}
              />
              <p className={styles.text}>Уведомления</p>
            </Link> */}
            {/* <Link to={'/profile'} className={styles.link}>
              <span className={styles.profile} />
              <p className={styles.text}>Профиль</p>
            </Link>
            <div className={styles.link} onClick={logout}>
              <span className={styles.exit} />
              <p className={styles.text}>Выйти</p>
            </div> */}
          </div>
        </>
      )}

      <ModalNavBar drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} />
    </header>
  );
};

export default React.memo(Header);
