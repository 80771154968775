import { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Drawer, List, ListItem, ListItemText } from '@mui/material';
import { AuthContext } from '@contexts/CurrUserContext';

const ModalNavBar = ({drawerOpen, toggleDrawer}) => {
  const { isLogged, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();  // Вызываем функцию логаута
    navigate('/auth');  // Перенаправляем на страницу авторизации
  };

    return (
        <Drawer
            anchor="left"
            open={drawerOpen}
            onClose={toggleDrawer(false)}
            sx={{ width: 300, '& .MuiDrawer-paper': { width: 300, maxWidth: '80%', height: '100vh' } }}
        >
            <List>
                <ListItem button component={Link} to="/projects">
                    <ListItemText primary="Проекты" />
                </ListItem>
                {/* <ListItem button component={Link} to="/tasks">
                    <ListItemText primary="Задачи" />
                </ListItem> */}
                {/* <ListItem button component={Link} to="/notifications">
                    <ListItemText primary="Уведомления" />
                </ListItem>
                <ListItem button component={Link} to="/profile">
                    <ListItemText primary="Профиль" />
                </ListItem> */}
                <ListItem button onClick={handleLogout}>
                    <ListItemText primary="Выйти" />
                </ListItem>
            </List>
        </Drawer>
    )
}

export default ModalNavBar