import React from 'react';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

type IProps = {
  text: string;
  link: string;
  linkText: string;
  img: string;
};

export const StartPageLink: React.FC<IProps> = ({ text, link, linkText, img }) => {
  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <h3 className={styles.title}>{text}</h3>
        <Link className={styles.link} to={link}>
          {linkText}
        </Link>
      </div>
      <img className={styles.image} src={img} alt={text} />
    </div>
  );
};
