import React, { useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './Task.module.scss';
import { TaskStatusEnum } from '@shared/enums';
import { RootState } from '@redux/store';
import { CheckListItemType, TaskDescType, NewCheckItemType } from '@shared/types';

import StatusSelect from '@components/UI/StatusSelect';
import SearchInput from '@components/UI/SearchInput';
import { CheckItem } from '@components/UI/CheckItem';
import CustomEditor from '@components/UI/CustomEditor';
import TaskDescriptionField from '@shared/UI/DescriptionField';
import { handleChangeCheckList } from '@shared/hooks';
import TextMultiline from '@shared/UI/Inputs/TextMultiline';
import { TextField } from '@mui/material';
import SubTaskList from 'features/SubTaskList';

interface IProps {
  taskDesc: TaskDescType;
  setTaskDesk: (v: TaskDescType) => void;
}

const Task: React.FC<IProps> = ({ taskDesc, setTaskDesk }) => {
  const [isMobile, setIsMobile] = useState(document.documentElement.scrollWidth < 1000);
  const { usersArr } = useSelector((state: RootState) => state.tasks);

  useLayoutEffect(() => {
    const handlePageWidth = () => {
      setIsMobile(document.documentElement.scrollWidth < 990);
    };
    handlePageWidth();
    window.addEventListener('resize', handlePageWidth);
    return () => window.removeEventListener('resize', handlePageWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeDesk = (v: TaskDescType) => {
    if (taskDesc.ReadOnly) return;
    setTaskDesk({ ...v, isChanged: true });
  };

  const handleReturn = (res: any) => (res.ok ? res.json() : Promise.reject(res.status));

  const sendCheckListEditRequest = async (uid: string, CheckNumber: number, isChecked: boolean) => {
    const pass = localStorage.getItem('userInfo');
    try {
      const response = await fetch('https://1c.rixap.ru/tm_rzrb01/Hs/tasks/checklistmark', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: pass || '',
        },
        body: JSON.stringify({
          UID: uid,
          CheckNumber: CheckNumber,
          Checked: isChecked,
        }),
      });

      const data = await handleReturn(response);
      console.log('Response data:', data);
    } catch (error) {
      console.error('Error updating checklist:', error);
    }
  };

  return (
    <div className={styles.task}>
      <span className={styles.formBackground}></span>
      <label className={styles.label}>
        <div className={styles.selectContainer}>
          <p className={styles.orgName}># {+taskDesc.Number}</p>
          <div className={styles.dateItem}>
            <span className={styles.dateLogo} />
            <p className={styles.dateText}>
              {taskDesc.Date.toString().split('-').join('.').split('T').join(' - ')}
            </p>
          </div>
        </div>
        <StatusSelect
          currOption={taskDesc.Status as TaskStatusEnum}
          options={taskDesc.ValidStatuses.map((item: string) => item) as TaskStatusEnum[]}
          setCurrOption={(e) =>
            setTaskDesk({ ...taskDesc, Status: e as TaskStatusEnum, isChanged: true })
          }
          disabled={taskDesc.ValidStatuses.length === 0}
        />
      </label>
      <TextMultiline
        label={'Тема задачи'}
        value={taskDesc.Subject}
        disabled
        style={{ margin: '36px 20px 50px' }}
      />
      {/* сюда подставить выбор проекта */}
      <div className={styles.additional}>
        {' '}
        <SearchInput
          currOption={taskDesc.Executor}
          options={usersArr}
          setCurrOption={(e) =>
            handleChangeDesk({ ...taskDesc, Executor: e, Status: TaskStatusEnum['InProgress'] })
          }
          styling={{
            margin: '0 0 0',
            padding: '0 0 0',
            headColor: isMobile ? '#BFC8E8' : '#FFFFFF',
            textColor: isMobile ? '#363942' : '#FFFFFF',
          }}
          heading={'Исполнитель'}
          dialogText={'Выбор исполнителя'}
          disabled={taskDesc.ReadOnly}
          name="Executor"
        />
        <SearchInput
          currOption={taskDesc.Initiator}
          options={usersArr}
          setCurrOption={(e) => handleChangeDesk({ ...taskDesc, Initiator: e })}
          styling={{
            margin: '0 0 0',
            padding: '0 0 0',
            headColor: isMobile ? '#BFC8E8' : '#FFFFFF',
            textColor: isMobile ? '#363942' : '#FFFFFF',
          }}
          heading={'Инициатор'}
          dialogText={'Выбор инициатора'}
          disabled={taskDesc.ReadOnly}
          name="Initiator"
        />
        <TaskDescriptionField
          value={taskDesc.Note}
          onChange={(e) => handleChangeDesk({ ...taskDesc, Note: e.target.value })}
          styling={{
            padding: '0 0 10px',
            margin: '15px 0 0',
            headColor: isMobile ? '#BFC8E8' : '#FFFFFF',
            textColor: isMobile ? '#363942' : '#FFFFFF',
          }}
        />
        <SubTaskList
          styles={styles}
          taskDesc={taskDesc}
          setTaskDesk={setTaskDesk}
          handleChangeDesk={handleChangeDesk}
          handleChangeCheckList={handleChangeCheckList}
          sendCheckListEditRequest={sendCheckListEditRequest}
        />
      </div>
    </div>
  );
};

export default Task;
