import styles from './styles.module.scss';
import { TasksModal } from '@widgets/Lists';
import { PageLayout } from '@entities/Layouts';
import { useContext, useEffect, useRef, useState } from 'react';
import { AuthContext } from '@contexts/CurrUserContext';
import { TasksDispatch } from '@redux/store';
import { NewTaskDeskDefault, TaskDescDefault } from '@shared/constants';
import { TaskDescType, NewTaskDescType } from '@shared/types';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router';
import { fetchDataPatchSimple, fetchDataPostSimple } from 'api';
import { handleChangeCheckList, handleNewTextComment } from '@shared/hooks';
import { SimpleBigButton, SimpleButton } from '@components/UI/Button';
import { refreshTasks } from '@redux/slices/tasksSlice';
import ProtectedRoute from '@shared/HOC/ProtectedRoute';
import { CreateNewTaskButton } from '@shared/UI/Buttons';
import { SimpleTextArea } from '@components/UI/SimpleTextArea';
import CustomEditor from '@components/UI/CustomEditor';
import { CheckItem } from '@components/UI/CheckItem';
import { CheckList } from '@components/UI/CheckList';
import NewTask from '@components/Tasks/NewTask';
import Preloader from '@components/UI/Preloader/Preloader';

const NewTaskPage = () => {
  const [readOnly, setReadOnly] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { userInfo, isLogged } = useContext(AuthContext);
  const { task } = useParams();
  const dispatch = useDispatch<TasksDispatch>();
  const [newTaskDesc, setNewTaskDesc] = useState<NewTaskDescType>(NewTaskDeskDefault);

  const handleSubmitForm = () => {
    setIsLoading(true);
    const reqBody = {
      Subject: newTaskDesc.Subject,
      Initiator: newTaskDesc.Initiator.UID,
      Project: newTaskDesc.Project.UID,
      Note: newTaskDesc.Note,
      Status: newTaskDesc.Status,
    };
    !!newTaskDesc.Executor.UID && Object.assign(reqBody, { Executor: newTaskDesc.Executor.UID });
    !!newTaskDesc.Parent?.UID && Object.assign(reqBody, { Parent: newTaskDesc.Parent.UID });
    !!newTaskDesc.DeadLine && Object.assign(reqBody, { DeadLine: newTaskDesc.DeadLine });
    !!newTaskDesc.CheckList?.length && Object.assign(reqBody, { CheckList: newTaskDesc.CheckList });
    fetchDataPostSimple('tasks/add', reqBody)
      .then((res) => {
        // setResultModal(true);
        // setTypeResultModal(ModalType['newApp']);
        // setNewAppNumber(res.Number);
        setNewTaskDesc(NewTaskDeskDefault);
        dispatch(refreshTasks());
      })
      .catch((err) => {
        console.error(err);
        // setResultModal(true);
        // setTypeResultModal(ModalType['err']);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <PageLayout
      heading={`Новая задача`}
      canGoBack={false}
      headerChildren={
        window.innerWidth > 1000 && !readOnly ? <CreateNewTaskButton link={'/tasks/new'} /> : <></>
      }
    >
      <>
        <div className={styles.column}>
          <TasksModal setReadOnly={setReadOnly} />
        </div>
        <div className={styles.additional}>
          <div className={styles.container}>
            <div className={styles.comments}>
              <SimpleTextArea
                placeholder={'Тема задачи'}
                name={'taskName'}
                value={newTaskDesc.Subject}
                handleChange={(e) => setNewTaskDesc({ ...newTaskDesc, Subject: e.target.value })}
                styling={{ padding: '15px 50px 0 0', headColor: '#BFC8E8', textColor: '#363942' }}
              />
              {/* <DescTextArea
                value={newTaskDesc.Note}
                handleChange={(e) => setNewTaskDesc({ ...newTaskDesc, Note: e.target.value })}
                name={'Описание'}
                styling={{ padding: ' 0 0 0', margin: '29px 0 0', textColor: '#BFC8E8' }}
                handleUploadFile={handleSetNewFileComment}
                isLoading={isLoading}
              /> */}

              <CustomEditor
                value={newTaskDesc.Note}
                handleChange={(e) => setNewTaskDesc({ ...newTaskDesc, Note: e.value })}
                name={'Добавить описание'}
                styling={{
                  padding: ' 0 0 10px',
                  margin: '15px 0 0',
                  headColor: '#BFC8E8',
                  textColor: '#363942',
                }}
              />
              <CheckList
                addNewCheckItem={() =>
                  setNewTaskDesc({
                    ...newTaskDesc,
                    CheckList: [
                      ...newTaskDesc.CheckList,
                      {
                        Checked: false,
                        CheckName: 'Новый пункт',
                        CheckNumber: newTaskDesc.CheckList.length + 1,
                      },
                    ],
                  })
                }
              >
                <div className={styles.subtasksItems}>
                  {!!newTaskDesc.CheckList?.length &&
                    newTaskDesc.CheckList.map((item, index) => (
                      <CheckItem
                        checkItemInfo={item}
                        onClick={() =>
                          setNewTaskDesc({
                            ...newTaskDesc,
                            CheckList: handleChangeCheckList(
                              newTaskDesc.CheckList,
                              'onClick',
                              '',
                              index
                            ),
                          })
                        }
                        onChange={(e) =>
                          setNewTaskDesc({
                            ...newTaskDesc,
                            CheckList: handleChangeCheckList(
                              newTaskDesc.CheckList,
                              'onChange',
                              e.target.value,
                              index
                            ),
                          })
                        }
                        canMoveUp={index !== 0}
                        handleMoveUp={() =>
                          setNewTaskDesc({
                            ...newTaskDesc,
                            CheckList: handleChangeCheckList(
                              newTaskDesc.CheckList,
                              'up',
                              '',
                              index
                            ),
                          })
                        }
                        canMoveDown={newTaskDesc.CheckList.length - 1 !== index}
                        handleMoveDown={() =>
                          setNewTaskDesc({
                            ...newTaskDesc,
                            CheckList: handleChangeCheckList(
                              newTaskDesc.CheckList,
                              'down',
                              '',
                              index
                            ),
                          })
                        }
                        handleDelete={() =>
                          setNewTaskDesc({
                            ...newTaskDesc,
                            CheckList: handleChangeCheckList(
                              newTaskDesc.CheckList,
                              'delete',
                              '',
                              index
                            ),
                          })
                        }
                        key={item.CheckNumber}
                        canConvertToTask={false}
                        createTask={() => {}}
                      />
                    ))}
                </div>
              </CheckList>
            </div>
          </div>
          <div className={styles.info}>
            <NewTask
              NewTaskDesk={newTaskDesc}
              setNewTaskDesc={setNewTaskDesc}
              // handleAddNewFile={handleAddNewFile}
              // handleDeleteFile={handleDeleteFile}
              // fileItems={taskFiles}
            />
            <div className={styles.loading}>
              {isLoading ? (
                <Preloader />
              ) : (
                <SimpleBigButton
                  text={'Создать заявку'}
                  handleClick={() => handleSubmitForm()}
                  styling={styles.submit}
                  disabled={
                    !newTaskDesc.Initiator.UID ||
                    !newTaskDesc.Subject ||
                    !newTaskDesc.Project.UID ||
                    isLoading
                  }
                />
              )}
            </div>
          </div>
        </div>
      </>
    </PageLayout>
  );
};

export default ProtectedRoute(NewTaskPage);
