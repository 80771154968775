import React, { useCallback, useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import { useParams } from 'react-router-dom';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';

import styles from './styles.module.scss';
import { TasksDispatch, RootState } from '@redux/store';
import { TaskListType } from '@shared/types';
import { AuthContext } from '@contexts/CurrUserContext';
import { TaskStatusEnum } from '@shared/enums';
import { fetchDataPostSimple } from 'api';
import {
  setFilterModalState,
  setFilterByMyTasks,
  resetFilerStatus,
  setFilterStatus,
} from '@redux/slices/filterSlices';

// import FilterInput from '@components/UI/FilterInput';
import Preloader from '@components/UI/Preloader/Preloader';
import { Card } from '@components/UI/Card';
import { ModalTasksFilter } from '@components/Modal/ModalTasksFilter';
import { BoardListCheckBox, FilterButton } from '@components/UI/ArrHeadingButtons';
import { ExtendButton } from '@components/UI/ExtendButton';
import { columnNames } from '@shared/configs';

type IProps = {
  setReadOnly: (v: boolean) => void;
};

export const TasksList: React.FC<IProps> = ({ setReadOnly }) => {
  const { task } = useParams();
  const { isOpen, isActive, statusFilter, initiatorFilter, executorFilter, projectFilter } =
    useSelector((state: RootState) => state.filter);
  const [currTasks, setCurrTasks] = useState<TaskListType[]>([]);
  const [boardChecked, setBoardChecked] = useState(false);
  const [currColumn, setCurrColumn] = useState('Waiting');
  const [isLoading, setIsLoading] = useState(false);
  const [currPage, setCurrPage] = useState(1);
  const [isEnd, setIsEnd] = useState(false);
  const dispatch = useDispatch<TasksDispatch>();
  const { userInfo } = useContext(AuthContext);

  // сборщик тела запроса из фильтров
  const appBodyReq = useCallback(() => {
    const reqBody = {};
    projectFilter.UID && Object.assign(reqBody, { Project: projectFilter.UID });
    initiatorFilter.UID && Object.assign(reqBody, { Initiator: initiatorFilter.UID });
    executorFilter.UID && Object.assign(reqBody, { Executor: executorFilter.UID });
    return reqBody;
  }, [projectFilter.UID, executorFilter.UID, initiatorFilter.UID]);

  // обновление списка заявок при применении фильтра и смены колонки
  useEffect(() => {
    setCurrPage(1);
    dispatch(setFilterModalState(false));
    setIsLoading(true);
    const reqBody = { Part: 1 };
    boardChecked && Object.assign(reqBody, { Desk: currColumn });
    fetchDataPostSimple('tasks/list', { ...reqBody, ...appBodyReq() })
      .then((res) => {
        const { Tasks, ReadOnly } = res;
        setCurrTasks(Tasks);
        setReadOnly(ReadOnly);
      })
      .catch((err) => console.error(err))
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appBodyReq, boardChecked, currColumn]);

  const handleOnClickByFilters = (v: 'My' | 'Appointed' | 'Done') => {
    dispatch(resetFilerStatus());
    switch (v) {
      case 'My':
        dispatch(setFilterByMyTasks({ UID: userInfo.UID, Name: userInfo.Name }));
        return;
      case 'Appointed':
        dispatch(setFilterByMyTasks({ UID: '', Name: '' }));
        dispatch(setFilterStatus(TaskStatusEnum['Appointed']));
        return;
      default:
        dispatch(setFilterByMyTasks({ UID: '', Name: '' }));
        dispatch(setFilterStatus(TaskStatusEnum['Done']));
        return;
    }
  };

  const fastFilters = useCallback(
    () => (
      <div className={styles.tagContainer}>
        <ScrollContainer className={styles.tags} vertical={false}>
          <button
            className={cn(styles.tag, initiatorFilter.UID === userInfo.UID && styles.tag_active)}
            onClick={() => handleOnClickByFilters('My')}
          >
            Мои задачи
          </button>
          <button
            className={cn(
              styles.tag,
              statusFilter.includes(TaskStatusEnum['Appointed']) && styles.tag_active
            )}
            onClick={() => handleOnClickByFilters('Appointed')}
          >
            Новая
          </button>
          <button
            className={cn(
              styles.tag,
              statusFilter.includes(TaskStatusEnum['Done']) && styles.tag_active
            )}
            onClick={() => handleOnClickByFilters('Done')}
          >
            Выполнена
          </button>
        </ScrollContainer>
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initiatorFilter.UID, statusFilter, userInfo.UID]
  );

  const deskFilters = useCallback(
    () => (
      <div className={styles.columns}>
        {columnNames.map((el) => (
          <div
            className={styles.column}
            onClick={() => setCurrColumn(el.callback)}
            style={{
              borderBottom: `4px solid ${currColumn === el.callback ? el.color : '#bfc8e8'}`,
            }}
          >
            <p className={styles.text}>{el.name}</p>
          </div>
        ))}
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currColumn]
  );

  const fetchMoreTasks = () => {
    const reqBody = { Part: currPage + 1 };
    setCurrPage((prev) => prev + 1);
    setIsLoading(true);
    boardChecked && Object.assign(reqBody, { Desk: currColumn });
    fetchDataPostSimple('tasks/list', { ...reqBody, ...appBodyReq() })
      .then((res) => {
        res.Tasks.length === 0 && setIsEnd(true);
        setCurrTasks([...currTasks, ...res.Tasks]);
      })
      .catch((err) => console.error(err));
  };

  return (
    <div className={cn(styles.info, isOpen && styles.info_blocked)} id="scrollableDiv">
      {/* <FilterInput onChange={handleSortTaskByDesc} /> */}
      <div className={styles.heading}>
        <FilterButton onClick={() => dispatch(setFilterModalState(true))} isActive={isActive} />
        <BoardListCheckBox onClick={() => setBoardChecked(!boardChecked)} isActive={boardChecked} />
      </div>

      {!boardChecked ? fastFilters() : deskFilters()}

      <>
        {currTasks.length === 0 ? (
          isLoading ? (
            <div style={{ margin: '40px auto 0' }}>
              <Preloader />
            </div>
          ) : (
            <div style={{ margin: '40px auto 0' }}>
              <p className={styles.text}>Заявок нет</p>
            </div>
          )
        ) : (
          <InfiniteScroll
            dataLength={currTasks.length}
            next={fetchMoreTasks}
            hasMore={!isEnd}
            loader={
              <div className={styles.preloader}>
                <Preloader />
              </div>
            }
            className={styles.cards}
            scrollThreshold="20px"
            scrollableTarget="scrollableDiv"
          >
            {currTasks.map((item) => (
              <Card
                key={item.UID}
                cardInfo={item}
                isActive={item.UID === task}
                onClickHandler={() => console.log(item.UID)}
              />
            ))}
          </InfiniteScroll>
        )}
      </>

      {isOpen && <ModalTasksFilter />}
    </div>
  );
};

export const TasksModal: React.FC<IProps> = ({ setReadOnly }) => {
  const [isExtend, setExtend] = useState(true);
  return (
    <div className={styles.appContainer}>
      <ExtendButton
        onClick={() => setExtend(!isExtend)}
        arrowDirection={isExtend ? 'left' : 'right'}
        backColor="#fff"
        className={cn(styles.extendButton, isExtend && styles.extendButton_active)}
      />
      <div className={cn(styles.applications, isExtend && styles.applications_open)}>
        <TasksList setReadOnly={setReadOnly} />
      </div>
    </div>
  );
};
