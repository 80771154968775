import React from 'react';
import { Modal, Box, TextField } from '@mui/material';

interface TaskModalProps {
  isOpen: boolean;
  onClose: () => void;
  taskName: string;
  onTaskNameChange: (name: string) => void;
  onTaskCreate: () => void;
}

const ModalCreateTask: React.FC<TaskModalProps> = ({ isOpen, onClose, taskName, onTaskNameChange, onTaskCreate }) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}
    >
      <Box sx={{ width: '100%', padding: '16px', backgroundColor: 'white', borderRadius: '8px' }}>
        <TextField
          fullWidth
          placeholder="Enter task name"
          value={taskName}
          onChange={(e) => onTaskNameChange(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              onTaskCreate();
            }
          }}
          autoFocus
        />
      </Box>
    </Modal>
  );
};

export default ModalCreateTask;
