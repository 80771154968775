import React, { ReactNode, useState } from 'react';
// import { useNavigate } from 'react-router';
import { useNavigate } from 'react-router-dom';


interface IUserProps {
  UID: string;
  FullName: string;
  Name: string;
  Email: string;
  IsCoordinator: boolean;
  IsExecutor: boolean;
  IsSeniorCoordinator: boolean;
  IsSeniorExecutor: boolean;
  Avatar: string;
}

export type authContextType = {
  isLogged: boolean;
  authLogged: boolean;
  userInfo: IUserProps;
  login: (v: IUserProps) => void;
  logout: () => void;
  handleLogin: () => void;
};

const DefaultUserProps = {
  UID: '',
  FullName: '',
  Name: '',
  Email: '',
  Avatar: '',
  IsCoordinator: false,
  IsExecutor: false,
  IsSeniorCoordinator: false,
  IsSeniorExecutor: false,
};

export const authContextDefaultValues: authContextType = {
  isLogged: false,
  authLogged: false,
  userInfo: DefaultUserProps,
  login: () => { },
  logout: () => { },
  handleLogin: () => { },
};

export const AuthContext = React.createContext<authContextType>(authContextDefaultValues);

export function AuthProvider({ children }: { children: ReactNode }) {
  const [isLogged, setIsLogged] = useState(authContextDefaultValues.isLogged);
  const [authLogged, setAuthLogged] = useState(authContextDefaultValues.authLogged);
  const [userInfo, setUserInfo] = useState(authContextDefaultValues.userInfo);
  // const navigate = useNavigate();

  const login = (v: any) => {
    const {
      UID,
      FullName,
      Name,
      Email,
      Avatar,
      IsCoordinator,
      IsExecutor,
      IsSeniorCoordinator,
      IsSeniorExecutor,
    } = v;
    setUserInfo({
      UID,
      FullName,
      Name: Name ? Name : '',
      Email,
      Avatar,
      IsCoordinator,
      IsExecutor,
      IsSeniorCoordinator,
      IsSeniorExecutor,
    });
    setIsLogged(true);
  };

  const handleLogin = () => {
    setAuthLogged(true);
  };

  // const navigate = useNavigate();

  const logout = () => {
    setIsLogged(false);
    setAuthLogged(false);
    setUserInfo(DefaultUserProps);
    localStorage.removeItem('userInfo');
    localStorage.removeItem('appFilters');
    // navigate('/auth');
  };

  const value = {
    isLogged,
    authLogged,
    userInfo,
    login,
    logout,
    handleLogin,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
