import React from 'react';
import { TextField, InputLabel, Box } from '@mui/material';

const TaskDescriptionField = ({ value, onChange, styling }) => {
  function stripHtmlTags(htmlString) {
    return htmlString.replace(/<[^>]*>?/gm, ''); // Удаление HTML-тегов
  }

  const strippedText = stripHtmlTags(value);

  return (
    <Box>
      <InputLabel
        htmlFor="task-description"
        shrink
        sx={{ color: styling.headColor, fontSize: '22px', fontWeight: '400' }}
      >
        Описание
      </InputLabel>
      <TextField
        id="task-description"
        multiline
        rows={4}
        value={strippedText}
        onChange={onChange}
        variant="outlined"
        fullWidth
        InputProps={{
          sx: {
            fieldset: {
              border: 'none',
            },
          },
        }}
        sx={{
          borderBottom: '1px solid #bfc8e8',
          color: styling.textColor,
          '& .MuiInputBase-root': {
            padding: 0,
          },
        }}
        disabled
      />
    </Box>
  );
};

export default TaskDescriptionField;
