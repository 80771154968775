import React from 'react';
import styles from './styles.module.scss';
import { NavSideBar, Header, Footer, SubHeader } from '@entities/Components';
import { useLocation } from 'react-router-dom';
import { Outlet } from 'react-router';

const LayoutSubHeader: React.FC = () => {
  const location = useLocation();
  let heading = '';

  switch (location.pathname) {
    case '/tasks':
      heading = 'Управление задачами';
      break;
    default:
      heading = '';
      break;
  }

  return (
    <div className={styles.content}>
      <SubHeader />
      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default LayoutSubHeader;