import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchProjects, fetchAddProjects, fetchDeleteProjects } from './projectsApi';
import { IProjects } from '@shared/types';

interface IUserState {
  status: 'idle' | 'success' | 'loading' | 'failed';
  error: unknown;
  projects: IProjects;
}

export const getProjects = createAsyncThunk(
  '@@projects/getProjects',
  async (arg: { token: string }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { token } = arg;
      const response = await fetchProjects(token);
      const json = await response.json();
      return fulfillWithValue(json);
    } catch (error: unknown) {
      return rejectWithValue(error);
    }
  }
);

export const addProjects = createAsyncThunk(
  '@@projects/addProjects',
  async (arg: { token: string, body: {} }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { token, body } = arg;
      const response = await fetchAddProjects(token, body);
      const json = await response.json();
      return fulfillWithValue(json);
    } catch (error: unknown) {
      return rejectWithValue(error);
    }
  }
);

export const deleteProjects = createAsyncThunk(
  '@@projects/deleteProjects',
  async (arg: { token: string, body: { UID: string } }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { token, body } = arg;
      const response = await fetchDeleteProjects(token, body);
      if (response.ok) {
        return fulfillWithValue(undefined); // Возвращаем undefined или пустой объект
      } else {
        return rejectWithValue(await response.text());
      }
    } catch (error: unknown) {
      return rejectWithValue(error);
    }
  }
);

const initialState: IUserState = {
  status: 'idle',
  error: null,
  projects: {
    ReadOnly: true,
    Projects: []
  },
};

const projectsSlice = createSlice({
  name: '@@projects',
  initialState,
  reducers: {
    addProject: (state, action) => {
      state.projects.Projects.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProjects.fulfilled, (state, action) => {
        state.status = 'success';
        state.projects = action.payload;
      })
      .addCase(addProjects.fulfilled, (state, action) => {
        state.status = 'success';
        state.projects.Projects.push(action.payload);
      })
      .addCase(deleteProjects.fulfilled, (state, action) => {
        state.status = 'success';
        const uidToDelete = action.meta.arg.body.UID;
        state.projects.Projects = state.projects.Projects.filter(project => project.UID !== uidToDelete);
      })
      .addMatcher(
        (action) => action.type.endsWith('/pending'),
        (state) => {
          state.status = 'loading';
          state.error = '';
        }
      )
      .addMatcher(
        (action) => action.type.endsWith('/rejected'),
        (state, action) => {
          state.status = 'failed';
          state.error = action.payload.statusText;
        }
      );
  },
});

export const projectsReducer = projectsSlice.reducer;

export const { addProject } = projectsSlice.actions;

export const selectProjects = (state: { projects: IUserState }) => state.projects.projects;
export const selectProjectsStatus = (state: { projects: IUserState }) => state.projects.status;
