export const columnNames = [
  { name: 'Ожидание', callback: 'Waiting', color: '#8391a5' },
  { name: 'Делать', callback: 'ToDo', color: '#fabb18' },
  { name: 'В работе', callback: 'InProgress', color: '#378dfe' },
  { name: 'Выполнена', callback: 'Done', color: '#91bd13' },
];

export const TaskStatuses = [
  { name: 'Черновик', callback: 'Черновик', color: '#8391A5' },
  { name: 'Назначена', callback: 'Назначена', color: '#FABB18' },
  { name: 'Ожидание', callback: 'Ожидание', color: '#FABB18' },
  { name: 'В работе', callback: 'ВРаботе', color: '#378DFE' },
  { name: 'Выполнена', callback: 'Выполнена', color: '#91BD13' },
  { name: 'Завершена', callback: 'Завершена', color: '#91BD13' },
  { name: 'Отменена', callback: 'Отменена', color: '#FC0800' },
  { name: 'Отложена', callback: 'Отложена', color: '#FABB18' },
];
