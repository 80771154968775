import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchPOSTData } from 'api';

type NotificationType = { UID: string };

interface notificationState {
  notificationArr: NotificationType[];
  notificationsNumber: number;
  isNotificationLoading: boolean;
}

const initialState: notificationState = {
  notificationArr: [],
  notificationsNumber: 0,
  isNotificationLoading: false,
};

const NotificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotificationSliceIsLoading(state, action: PayloadAction<boolean>) {
      return { ...state, isLoading: action.payload };
    },
    addNotificationsNumber(state, action: PayloadAction<{ Number: number }>) {
      return { ...state, notificationsNumber: action.payload.Number };
    },
    addNotificationsArr(state, action: PayloadAction<NotificationType[]>) {
      return {
        ...state,
        notificationArr: action.payload,
        notificationsNumber: action.payload.length,
      };
    },
    removeNotification(state, action: PayloadAction<string>) {
      const newArr = state.notificationArr.filter((item) => item.UID !== action.payload);
      return {
        ...state,
        notificationArr: newArr,
        notificationsNumber: newArr.length,
      };
    },
    setNotificationErrorMessage(state, action: PayloadAction<string>) {
      console.log(action.payload);
    },
  },
});

export const {
  setNotificationSliceIsLoading,
  addNotificationsNumber,
  addNotificationsArr,
  removeNotification,
  setNotificationErrorMessage,
} = NotificationSlice.actions;

export default NotificationSlice.reducer;

export const fetchNotificationsArr = createAsyncThunk(
  'notifications/fetchNotifications',
  async (_, { dispatch }) => {
    await fetchPOSTData('messages/new', (res: any) => dispatch(addNotificationsArr(res)));
  }
);
